/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ttf_explanation_dialog_text = () => `Time To Finality (TTF) is the duration of time it takes for a transaction to be considered final in a blockchain network. It’s essentially the time it takes for a transaction to become permanent in a blockchain’s ledger.<br /><br />The TTF depends on the consensus algorithm and can vary based on factors like block time, the presence of forks, and other external parameters. On the dashboard, we display Max TTF, which represents the maximum time it takes for a transaction to be considered final.`
