/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const max_tps_explanation_dialog_text = () => `Max Recorded TPS represents the highest number of transactions per second achieved by a blockchain in its history.<br /><br />This metric is calculated over 100 consecutive blocks by dividing the total transactions in this period by the cumulative block duration in seconds.`
