/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const launch_date_explanation_dialog_text = () => `This metric indicates how long the blockchain has been in operation. It is calculated from either the date of the first block creation (genesis block) or the official network launch announcement, depending on which occurs later.<br /><br />For forked chains, the launch date is counted from the date of the fork.`
