/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const theor_tps_explanation_dialog_text = () => `Max Theoretical TPS shows how many transactions per second the blockchain is theoretically capable of handling.<br /><br />This metric is calculated by taking the block size measured in gas/bytes/computational units and dividing it by the size of the smallest transaction possible to determine the maximum number of transactions that fit into the block (in most blockchains, the smallest transaction is a transfer of a native token). Then the number of transactions is divided by the block time to compute the metric.<br /><br />In some cases, Max Theoretical TPS is determined based on scalability tests provided by the blockchain's team.<br /> <br />Max Theoretical TPS is based on theoretic assumptions, real throughput may vary greatly due to networking, latency, location of nodes, and other factors.`
