/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const governance_explanation_dialog_text = () => `Blockchains rely on various governance mechanisms for decision-making on project direction, distribution of funds, and ongoing updates, and to ensure the smooth and efficient operation of the underlying protocol and ecosystem.<br /><br /><strong>On-chain governance.</strong> On-chain governance is a mechanism allowing decentralized communities to update a blockchain through direct on-chain voting.<br /><br /><strong>Off-chain governance.</strong> Off-chain governance is a form of blockchain governance where decisions are informally made off chain, (discussions typically happen in social media or forums). The result of discussions is based on the unanimous agreement among the community.<br /><br /><strong>Council.</strong> Council governance is similar to the operational structure of boards of directors in traditional web2 companies. It involves a selected group of organizations or individuals responsible for determining the blockchain’s future, strategy, and allocation of funds.<br /><br /><strong>Multisig.</strong> Similar to the operational framework of private companies, this governance model involves a single entity empowered to independently adjust the core parameters of the blockchain, allocate funds, and redefine the strategy.`
