import { writable } from 'svelte/store'

import type { ChainConfig } from '$lib/constants/chains'

export const getListedDialogStore = writable<{ open: boolean; chainConfig?: ChainConfig }>({
	open: false,
})

export const metricDialogStore = writable<{
	open: boolean
	type: 'tps' | 'max-tps' | 'theor-tps' | 'blocktime' | 'ttf' | 'governance' | 'launch-date'
}>({ open: false, type: 'tps' })
