/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tps_explanation_dialog_text = () => `Real-time TPS shows how many transactions per second the blockchain is processing.<br /><br />This metric is calculated by taking different time intervals (hour/day/week/month) and calculating the total amount of transactions in them. Then the number of transactions is divided by the number of seconds in an interval.<br /><br />Only transactions with economic value are included, system transactions and transactions needed for consensus are excluded. Internal/inner transactions are not counted as additional transactions.`
